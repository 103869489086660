import React, { useContext, useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useAmplitude } from 'react-amplitude-hooks';
import Big from 'big.js';
import { getTranslation } from 'Helpers/translations';
import { useFormatNumber } from 'Utils/formatNumber';
import { Chip } from 'Components/shared/Chip';
import { NumberInput } from 'Components/shared/formElements';
import { CartContext } from 'States/cart/cartState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import DescriptionTableRow from './components/DescriptionTableRow';
import TableRowWrapper from './components/TableRowWrapper';
import MobileActionButtons from './components/MobileActionButtons';
import DesktopActionButtons from './components/DesktopActionButtons';
import { UiContext } from 'States/ui/uiState';
import usePurchaseMethods from 'Hooks/usePurchaseMethods';
import Text from 'Components/shared/Text';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import { EmailIcon } from 'Components/shared/symbols';

const ItemRow = ({
  uniqueAssetId,
  defaultLanguage,
  currency,
  index,
  toggleRow,
  rowExpanded,
  assetUnavailable = false,
  setQuantitiesValidity
}) => {
  const [amount, setAmount] = useState(undefined);
  const [isRounded, setRounded] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const debounceRoundingRef = useRef(null);
  const debounceLimitModalOpeningRef = useRef(null);
  const {
    cartItems,
    fullAssetInfo,
    removeFromCart,
    setQuantity,
    pendingCartOperation,
    setPendingQuantityUpdate
  } = useContext(CartContext);
  const {
    config: {
      checkoutSettings: {
        limit
      }
    }
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  
  const isLimitEnabled = limit?.enabled && limit?.amount;
  const {
    Name,
    Description,
    UnitOfMeasureCode,
    CurrencyCode,
    DecimalPrecision,
    Translations,
    SparkFactor,
    maxPurchasableUnits
  } = fullAssetInfo[uniqueAssetId];

  useEffect(() => {
    if (assetUnavailable) {
      setAmount(0);
      return;
    }
    if (cartItems[uniqueAssetId].UnitAmount <= maxPurchasableUnits) {
      setAmount(cartItems[uniqueAssetId].UnitAmount);
      return;
    }
    setQuantity(uniqueAssetId, Big(maxPurchasableUnits).times(SparkFactor));
    setAmount(maxPurchasableUnits);
  }, [uniqueAssetId]);

  const formatNumber = useFormatNumber();
  const {
    breakpoints: { md, lg, xxl }
  } = useContext(UiContext);
  const { logEvent } = useAmplitude((inheritedProps) => ({
    ...inheritedProps,
  }));

  const handleRemoveAsset = useCallback(() => {
    removeFromCart(uniqueAssetId);
    logEvent('Cart changed');
  }, [removeFromCart, uniqueAssetId]);

  const handleChangeValue = (unitAmount) => {
    setRounded(false);
    if (debounceRoundingRef.current) {
      clearTimeout(debounceRoundingRef.current);
    }
    if (debounceLimitModalOpeningRef.current) {
      clearTimeout(debounceLimitModalOpeningRef.current);
    }
    if (assetUnavailable) {
      return;
    }
    setAmount(unitAmount);
    logEvent("Amount changed_add to cart", {
      amount: unitAmount
    });
    if (unitAmount > maxPurchasableUnits) {
      return;
    }
    setPendingQuantityUpdate();

    if (isLimitEnabled && unitAmount > limit.amount && limit.emailAddress) {
      debounceLimitModalOpeningRef.current = setTimeout(() => setShowLimitModal(true), 1500);
      return;
    }

    debounceRoundingRef.current = setTimeout(() => {
      const sparkAmount = Big(unitAmount).times(SparkFactor);
      const mod = sparkAmount.mod(1);
      if (mod.eq(0)) {
        setQuantity(uniqueAssetId, sparkAmount);
        return;
      }
      let roundedSparks = sparkAmount.round(0);
      if (roundedSparks.eq(0)) {
        roundedSparks = roundedSparks.plus(1);
      }
      const roundedUnits = roundedSparks.div(SparkFactor).toNumber();
      setRounded(true);
      setAmount(roundedUnits);
      setQuantity(uniqueAssetId, roundedSparks);
    }, 1500);
  };

  const translatedName = useMemo(() => getTranslation(
    "Name", lang, defaultLanguage, Translations
  ) || Name, [lang]);

  const translatedDescription = useMemo(() => getTranslation(
    "Description", lang, defaultLanguage, Translations
  ) || Description, [lang]);

  const { data: currentPrice } = usePurchaseMethods.purchaseMethodsQuery({
    enabled: false,
    select: useCallback(({ value }) => {
      const price = value.find((asset) => asset.Id === uniqueAssetId)?.Price;
      return price;
    }, [])
  });

  const totalAmount = useMemo(() => {
    return assetUnavailable
      ? `0 ${currency}`
      : `${formatNumber(Big(currentPrice).times(amount || 0).round(2, Big.roundUp).toNumber())} ${currency}`;
  }, [assetUnavailable, amount, currency, currentPrice]);


  const maxPossibleAmount = useMemo(() => `${formatNumber(maxPurchasableUnits)} ${
    UnitOfMeasureCode || CurrencyCode || translatedName
  }`, [maxPurchasableUnits, UnitOfMeasureCode, CurrencyCode, translatedName]);

  const badgeTitle = useMemo(() => {
    if (assetUnavailable) {
      return i18nextTranslate(i18nextKeys.cartBadgeSoldOut);
    }
    const isMaxAmount = Number(amount) === Number(maxPurchasableUnits);
    if (isMaxAmount) {
      return i18nextTranslate(i18nextKeys.cartBadgeMaxQuantity);
    }
    return null;
  }, [amount]);

  const setValidity = (validity) => {
    if (!validity) {
      setRounded(false);
    }
    setQuantitiesValidity((state) => {
      return {
        ...state,
        [uniqueAssetId]: validity
      }
    })
  };

  const getConfigTranslation = (translations) => translations[lang] || translations[defaultLanguage] ||
    translations["en"] || Object.values(translations).find(translation => !!translation);

  const openEmailClient = () => {
    const translatedSubject = limit.emailSubject ? getConfigTranslation(limit.emailSubject) : '';
    const emailSubject = translatedSubject ? `subject=${translatedSubject}` : '';
    const translatedBody = limit.emailBody ? getConfigTranslation(limit.emailBody) : '';
    const emailBody = translatedBody ? `body=${translatedBody}` : '';
    window.location.href = `mailto:${limit.emailAddress}${emailSubject || emailBody
      ? `?${emailSubject}${emailSubject && emailBody ? '&' : ''}${emailBody}`
      : ''
      }`;
  };

  return (
    <>
      <TableRowWrapper
        dataQa={`cart${assetUnavailable ? '-unavailable' : ''}-asset-${index}`}
        index={index}
      >
        <td className={`pl-16 text-sm py-12 ${md ? 'h-full' : 'py-12 h-104'} ${!md ? 'w-4/6' : ''}`}>
          {
            md ? (
              <span className="flex flex-row gap-12 xxl:gap-16 items-center text-left">
                {badgeTitle && (
                  <Chip
                    text={badgeTitle}
                    dataQa={`chip-${index}`}
                    width={xxl ? "104px" : "90px"}
                  />
                )}
                <span className='xxl:text-sm'>{translatedName}</span>
              </span>
            ) : (
              <div className="flex flex-col h-full justify-between gap-6">
                <span>{translatedName}</span>
                <div className="flex flex-col gap-12">
                  {badgeTitle && (
                    <Chip
                      text={badgeTitle}
                      dataQa={`chip-${index}`}
                      width="90px"
                    />
                  )}
                  {!assetUnavailable && (
                    <span className='text-sm'>
                      <span className='text-xs font-bold'>
                        {i18nextTranslate(i18nextKeys.commonTotal)}:{' '}
                      </span>
                      {totalAmount}
                    </span>
                  )}
                </div>
              </div>
            )
          }
        </td>
        {lg && (
          <td className="px-12 py-8 w-1/6 text-right text-sm xxl:color-8">
            {`1 ${
              UnitOfMeasureCode || CurrencyCode || translatedName
            } = ${formatNumber(currentPrice)} ${currency}`}
          </td>
        )}
        <td className={`py-12 w-1/4 md:w-1/3 ${!md && 'align-top'}`}>
          <div className="flex flex-col md:flex-row gap-4 md:gap-12 md:items-center">
            <NumberInput
              dataQa={`${assetUnavailable ? 'unavailable-' : ''}asset-row-${index}-input`}
              style={{
                maxWidth: '105px',
                height: xxl ? '30px' : '28px'
              }}
              onChange={handleChangeValue}
              value={assetUnavailable ? 0 : amount}
              max={maxPurchasableUnits}
              disabled={assetUnavailable}
              readOnly={pendingCartOperation}
              setValidity={setValidity}
              scale={DecimalPrecision || 0}
              decimal={!!DecimalPrecision}
              textRight
              showOwnErrors
            />
            {!assetUnavailable ?
              isRounded ? (
                <Text
                  textStyle="h3"
                  color="color-red"
                  dataQa={`asset-${index}-rounded`}
                >
                  {i18nextTranslate(i18nextKeys.cartMessageRounded)}
                </Text>
              ) : (
              <span className="md:flex md:flex-col text-xs leading-tight overflow-hidden">
                <span className="font-bold text-left">
                  {`${i18nextTranslate(i18nextKeys.commonMax)}:`}
                </span>{' '}
                <span
                  className="text-left overflow-hidden"
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {maxPossibleAmount}
                </span>
              </span>
            ) : null}
          </div>
        </td>
        {md ? (
          <td className="pl-8 py-8 pr-24 text-sm text-right w-1/5 xxl:color-8">
            {totalAmount}
          </td>
        ) : null}
        {!md ? (
          <MobileActionButtons
            toggleRow={toggleRow}
            handleRemoveAsset={handleRemoveAsset}
            rowExpanded={rowExpanded(uniqueAssetId)}
          />
        ) : (
          <DesktopActionButtons
            toggleRow={toggleRow}
            handleRemoveAsset={handleRemoveAsset}
            rowExpanded={rowExpanded(uniqueAssetId)}
          />
        )}
      </TableRowWrapper>
      <TableRowWrapper
        dataQa={`cart${assetUnavailable ? '-unavailable' : ''}-asset-${index}-description`}
        rowExpanded={rowExpanded(uniqueAssetId)}
        index={index}
      >
        <DescriptionTableRow
          showConversionRate={!lg}
          unitOfMeasure={UnitOfMeasureCode}
          currencyCode={CurrencyCode}
          description={translatedDescription}
          name={translatedName}
          price={currentPrice}
          currency={currency}
        />
      </TableRowWrapper>
      {isLimitEnabled && limit?.emailAddress ? (
        <ConfirmationModal
          show={showLimitModal}
          icon={<EmailIcon size="30" />}
          close={() => setShowLimitModal(false)}
          onConfirm={openEmailClient}
          confirmButtonText={i18nextTranslate(i18nextKeys.commonEmail)}
          title={getConfigTranslation(limit.notificationText)}
          dataQa={`asset-${index}-limit-modal`}
          showCloseButton={false}
        />
      ) : null}
    </>
  );
};

export default ItemRow;
